<template>
	<div>
		<div class="container">
			<div class="header">
				<a @click="$router.back(-1)"><img src="../assets/images/back.png" alt=""></a> 编辑收货地址</div>
			<div class="blian22"></div>
		</div>
		<div class="account-list">
			<ul>
				<li>
					<span>收货人</span>
					<div class="alright">
						<input type="text" placeholder="请填写姓名" v-model="addressInfo.recName">
					</div>
				</li>
				<li>
					<span>联系电话</span>
					<div class="alright">
						<input type="number" placeholder="请填写收货人手机号" v-model="addressInfo.recPhone" maxlength="11">
						<img src="../assets/images/right.png" alt="" class="rightImg">
					</div>
				</li>
				<li @click="addAddress">
					<span>所在地区</span>
					<div class="alright">
						<em>{{addressInfo.province}}{{addressInfo.city}}{{addressInfo.region}}</em>
						<img src="../assets/images/right.png" alt="" class="rightImg">
					</div>
				</li>
				<div class="textarea">
					<textarea v-model="addressInfo.detail" name="" id="" cols="30" rows="10" placeholder="请输入详细地址"></textarea>
				</div>
			</ul>
		</div>
		<!-- 选择地区 -->
		<Address @hidden="hiddenShow" v-show="showAddress" :addressInfo = 'addressInfo' ref="address"></Address>
		<!-- 选择地区 end -->
		<div class="blian52"></div>
		<!--底部按钮 begin-->
		<div class="cont_foot_btn" @click="add">
			<a>确认</a>
		</div>
		<!--底部按钮 end-->
	</div>
</template>
<script>
	import { addUserAddr,modifyUserAddr } from "@/assets/js/api";
	import Address from '@/components/V-Address.vue';
	import { Toast } from 'mint-ui';
	export default {
		name: "myaddaddress",
		components: {
			Address //引用组件
		},
		data() {
			return {
				addressInfo:{},
				infos:{},
				showAddress: false,
				detail: '',
				recName: '',
				recPhone: '',
			}
		},
		methods: {
			addAddress: function() {
				this.showAddress = true
			},
			hiddenShow: function(data) {
				console.log(data)
				this.showAddress = false
			},
			getmodifyUserAddr: function() {
				//获取地址列表
				modifyUserAddr({}).then(res => {
					if(res.code == 0) {
						this.userAddrs = res.userAddrs;
					}
				});
			},
			//添加地址
			add() {
				if(this.addressInfo.recName == '') {
					Toast({
						message: '请输入收货人',
						position: 'middle',
						duration: 1000
					});
				} else if(this.addressInfo.recPhone == '') {
					Toast({
						message: '请输入联系方式',
						position: 'middle',
						duration: 1000
					});
				} else if(this.addressInfo.province == '') {
					Toast({
						message: '请选择收货地址',
						position: 'middle',
						duration: 1000
					});
				} else if(this.addressInfo.detail == '') {
					Toast({
						message: '请输入详细地址',
						position: 'middle',
						duration: 1000
					});
				} else {
					let params = {
						addrId:this.addressInfo.addrId,
						province: this.addressInfo.province,
						city: this.addressInfo.city,
						region: this.addressInfo.region,
						recName: this.addressInfo.recName,
						detail: this.addressInfo.detail,
						recPhone: this.addressInfo.recPhone,
					}
					modifyUserAddr(params).then(res => {
						if(res.code == 0) {
							this.$router.go(-1)
							Toast({
								message: res.msg,
								position: 'middle',
								duration: 1000
							});
						}
					});
				}
			}
		},
		mounted() {
			this.addressInfo = JSON.parse(this.$route.query.info);
			console.log(this.addressInfo)
		}
	};
</script>