<template>
<div>
  <!-- 选择地区 -->
  <div class="sh_city">
    <h3>请选择邮寄地址 <span id="sh_cancel" @click="Hidden()"><img src="../assets/images/close.png"/></span></h3>
    <ul class="sh_tab_city">
        <li :class="tabIndex == 1 ? 'on' :''" @click="changeTab(1)">{{addressInfo.province}}</li>
        <li :class="tabIndex == 2 ? 'on' :''" @click="changeTab(2)"  v-if=" addressInfo.province != '请选择'">{{addressInfo.city}}</li>
        <li :class="tabIndex == 3 ? 'on' :''" @click="changeTab(3)" v-if=" addressInfo.city != '请选择'">{{addressInfo.region}}</li>
      </ul>
      <div class="city_proj_list">
          <div class="provinces" v-if="tabIndex == 1">
            <ul>
              <li :class="addressInfo.province == item.provinceName ? 'on': ''" v-for="(item,index) in proviceArr" :key="index" @click="getProvince(item,item.provinceId)">{{item.provinceName}}</li>
            </ul>
          </div>
          <div class="provinces" v-if="tabIndex == 2">
            <ul>
                <li :class="addressInfo.city == item.cityName ? 'on': ''" v-for="(item,index) in cityArr" :key="index" @click="getCity(item,item.cityId)">{{item.cityName}}</li>               
            </ul>
          </div>
          <div class="provinces" v-if="tabIndex == 3">
            <ul>
              <li :class="addressInfo.region == item.regionName ? 'on': ''" v-for="(item,index) in areaArr" :key="index" @click="getArea(item,item.regionId)">{{item.regionName}}</li>
            </ul>
          </div>
      </div>
  </div>
  <!--遮罩层-->
  <div class="popmask" @click="Hidden()"></div>
</div>
</template>
<style lang="scss" scoped>

</style>
<script>
	
	import { provinces,cityOrRegions } from "@/assets/js/api";
export default {
  name: "v-address",
  data: () => {
    return {
      // 选中省市区
      proviceName:"请选择",
      provinceIndex:-1,
      cityName:"请选择",
      cityIndex:-1,
      areaName:"请选择",
      areaIndex:-1,
      // 数组
      proviceArr:[],
      cityArr:[],
      areaArr:[],
      tabIndex:1,
      address:{
        provinceName:"",
        cityName:"",
        areaName:""
      }
    };
  },  
  props:{
  	addressInfo:{
  		type:Object,
  		default:{
  			province:'请选择',
  			city:'请选择',
  			region:'请选择'
  		}
  	}
  },
  methods: {
    Hidden(){
      //通过$emit引用组件传过来的hidden()事件
      this.$emit("hidden",this.addressInfo);
    },
    changeTab:function(index){
      this.tabIndex = index
    },
    getProvince:function(item,index){
      this.tabIndex = 2;
      this.addressInfo.province = item.provinceName;
      this.addressInfo.city='请选择';
      this.addressInfo.region='请选择';
      //获取市
      this.getCityInfo(index)
    },
    getCity:function(item,index){
      this.tabIndex = 3;
      this.addressInfo.city = item.cityName;
      this.getAreaInfo(index);//获取县
    },
    getArea:function(item,index){
      this.$emit("hidden",this.addressInfo);
      this.addressInfo.region = item.regionName;
    },
    //获取省
			getprovinces() {
				provinces({}).then(res => {
					if(res.code == 0) {
						this.proviceArr = res.provinces;
						if(this.addressInfo.province!='请选择'){
							//如果有编辑信息
							this.getCityInfo(this.rePreId(this.addressInfo.province,res.provinces))//获取市的信息
						}
					}
				});
			},
			//获取市
			getCityInfo(provId){
				cityOrRegions({type:1,queryId:provId}).then(res => {
					if(res.code == 0) {
						this.cityArr = res.cityOrRegions;
						if(this.addressInfo.city!='请选择'){
							//如果有编辑信息
							this.getAreaInfo(this.reCityId(this.addressInfo.city,res.cityOrRegions))//获取市的信息
						}
					}
				});
			},
			//获取县
			getAreaInfo(provId){
				cityOrRegions({type:2,queryId:provId}).then(res => {
					if(res.code == 0) {
					  this.areaArr =  res.cityOrRegions;
					}
				});
			},
			//返回对应的省市id
			rePreId(name,arr){
				let preId = '';
				for(var i = 0;i<arr.length;i++){
					if(arr[i].provinceName == name){
						preId = arr[i].provinceId;
						break
					}
				}
				return preId
			},
			//范湖市id
			reCityId(name,arr){
				let cityId = '';
				for(var i = 0;i<arr.length;i++){
					if(arr[i].cityName == name){
						cityId = arr[i].cityId;
						break
					}
				}
				return cityId
			}
  },
  mounted() {
  	this.getprovinces()
  }
};
</script>
