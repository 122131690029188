<template>
	<div>
		<div class="container">
			<div class="header">
				<a @click="$router.back(-1)"><img src="../assets/images/back.png" alt=""></a> 新增收货地址</div>
			<div class="blian22"></div>
		</div>
		<div class="account-list">
			<ul>
				<li>
					<span>收货人</span>
					<div class="alright">
						<input type="text" placeholder="请填写姓名" v-model="recName">
					</div>
				</li>
				<li>
					<span>联系电话</span>
					<div class="alright">
						<input type="number" placeholder="请填写收货人手机号" v-model="recPhone" maxlength="11">
						<img src="../assets/images/right.png" alt="" class="rightImg">
					</div>
				</li>
				<li @click="addAddress">
					<span>所在地区</span>
					<div class="alright">
						<em>{{address.province}}{{address.city}}{{address.region}}</em>
						<img src="../assets/images/right.png" alt="" class="rightImg">
					</div>
				</li>
				<div class="textarea">
					<textarea v-model="detail" name="" id="" cols="30" rows="10" placeholder="请输入详细地址"></textarea>
				</div>
			</ul>
		</div>
		<!-- 选择地区 -->
		<Address @hidden="hiddenShow" v-show="showAddress" ref="address" :addressInfo="addressInfo"></Address>
		<!-- 选择地区 end -->
		<div class="blian52"></div>
		<!--底部按钮 begin-->
		<div class="cont_foot_btn" @click="add">
			<a>确认</a>
		</div>
		<!--底部按钮 end-->
	</div>
</template>
<script>
	import { addUserAddr } from "@/assets/js/api";
	import Address from '@/components/V-Address.vue';
	import { Toast } from 'mint-ui';
	export default {
		name: "myaddaddress",
		components: {
			Address //引用组件
		},
		data() {
			return {
				addressInfo: {
					province: '请选择',
					city: '请选择',
					region: '请选择'
				},
				showAddress: false,
				detail: '',
				recName: '',
				recPhone: '',
				address: {
					province: '',
					city: '',
					region: ''
				}
			}
		},
		methods: {
			addAddress: function() {
				this.showAddress = true
			},
			hiddenShow: function(data) {
				this.address = data;
				this.showAddress = false
			},
			getAddress: function() {
				this.address = this.$refs["address"].address;
			},
			//添加地址
			add() {
				if(this.recName == '') {
					Toast({
						message: '请输入收货人',
						position: 'middle',
						duration: 1000
					});
				} else if(this.recPhone == '') {
					Toast({
						message: '请输入联系方式',
						position: 'middle',
						duration: 1000
					});
				} else if(this.address.province == '') {
					Toast({
						message: '请选择收货地址',
						position: 'middle',
						duration: 1000
					});
				} else if(this.detail == '') {
					Toast({
						message: '请输入详细地址',
						position: 'middle',
						duration: 1000
					});
				} else {
					let params = {
						province: this.address.province,
						city: this.address.city,
						region: this.address.region,
						recName: this.recName,
						detail: this.detail,
						recPhone: this.recPhone,
					}
					addUserAddr(params).then(res => {
						if(res.code == 0) {
							this.$router.go(-1)
							Toast({
								message: res.msg,
								position: 'middle',
								duration: 1000
							});
						}
					});
				}
			}
		},
		mounted() {
			this.getAddress()
		}
	};
</script>